import { t } from '../../../i18n';
import LandingPageButton from '../../../shared/LandingPageButton';
import { LandingButtonType } from '../../../types/landingButton';
import { SchemaColours, SchemaFiles } from '../../../types/schema';
import useLandingView from '../hooks/useLandingView';

const schemaFiles: SchemaFiles =
  require(`./../../../theme/${process.env.REACT_APP_THEME}/schemaFiles.tsx`).default;
const schemaColours: SchemaColours = require(`./../../../theme/${process.env.REACT_APP_THEME}/schemaColours.json`);

const LandingView = () => {
  const { buttons, setUserType, redirectTo } = useLandingView();
  const bgStyle =
    schemaFiles.images.backgroundImage &&
    schemaFiles.images.backgroundImageInLanding
      ? {
          backgroundImage: `url(${schemaFiles.images.backgroundImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }
      : {
          backgroundColor: schemaColours.backgroundColor,
          backgroundImage: 'none',
        };

  return (
    <section
      className="flex flex-col w-full h-screen items-center justify-center bg-primary"
      style={bgStyle}
    >
      <h1 className={`text-accentPrimary] text-center mx-auto my-5`}>
        <span>{t('Please select your')} </span>
        <br className="visible sm:hidden" />
        <span>{t('organisation type')}:</span>
      </h1>
      <div className="flex flex-col sm:flex-col gap-5 pt-2">
        {buttons
          .filter((e) => e.visible)
          .map((userButton) => (
            <LandingPageButton
              className="mx-auto border-2 border-solid border-white hover:border-bermuda hover:text-black w-full aspect-square min-w-min !bg-inherit hover:!bg-accentPrimary"
              key={userButton.title}
              btn={userButton}
              data-dd-action-name={userButton.title}
              onClick={(btn: LandingButtonType): void => {
                setUserType?.(btn.userType);
                redirectTo(btn.linkHref);
              }}
            />
          ))}
      </div>
    </section>
  );
};

export default LandingView;
