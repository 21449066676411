import Typography from '@mui/material/Typography';
import cn from 'classnames';
import React from 'react';

type Props = {
  /** Any controls, it can be buttons */
  actions?: React.ReactNode;
  /** The additional class name of the page title */
  className?: string;
  /** The page title description */
  description?: string;
  /** The additional class name of the description */
  descriptionClassName?: string;
  /** The page title, it can be string or React element */
  pageTitle?: string | React.ReactNode;
};

const KdPageTitle: React.FC<Props> = ({
  actions,
  className,
  description,
  descriptionClassName,
  pageTitle,
}) => {
  const classes = cn(
    'text-[20px] sm:text-[28px] font-semibold mb-[5px]',
    className
  );

  const descriptionClasses = cn(
    'text-textXs sm:text-textBase text-contentColor leading-6',
    descriptionClassName
  );

  return (
    <>
      {pageTitle && (
        <Typography
          className={classes}
          component="h1"
          variant="h4"
        >
          {pageTitle}
        </Typography>
      )}
      {description && !actions && (
        <p className={`${descriptionClasses} mb-[15px] sm:mb-[30px]`}>
          {description}
        </p>
      )}
      {description && actions && (
        <div className="relative mb-[15px] sm:mb-[30px] pr-[230px]">
          <p className={descriptionClasses}>{description}</p>
          <div className="absolute right-0 bottom-[-1px] z-10">{actions}</div>
        </div>
      )}
    </>
  );
};
export default KdPageTitle;
