import React from 'react';

import { LandingButtonType } from '../types/landingButton';
import KdButton from './KdButton';

type Props = {
  btn: LandingButtonType;
  onClick: (btn: LandingButtonType) => void;
  onHover?: (btn: LandingButtonType) => void;
  onHoverEnd?: (btn: LandingButtonType) => void;
  className?: string;
};

const LandingPageButton = ({
  btn,
  onClick,
  onHover,
  onHoverEnd,
  className,
}: Props): JSX.Element => {
  const [hover, setHover] = React.useState(false);

  return (
    <KdButton
      className={`text-dashboardButtonText bg-dashboardButtonBackground ${className} pt-0 pb-0 ${
        btn.userType === 'CF' ? `max-h-100` : `max-h-40`
      }`}
      type="button"
      onClick={() => {
        if (onClick) {
          onClick(btn);
        }
      }}
      onMouseEnter={() => {
        setHover(true);
        if (onHover) {
          onHover(btn);
        }
      }}
      onMouseLeave={() => {
        setHover(false);
        if (onHoverEnd) {
          onHoverEnd(btn);
        }
      }}
      testId={btn.testId}
    >
      <div className={`flex flex-col ${
            btn.userType === 'CF' ? `xs:flex-col` : `xs:flex-row`
          } gap-4`}>
        <btn.img
          alt={`${btn.img}`}
          className={`w-[${
            btn.userType === 'CF' ? `128px` : `64px`
          }] h-[${
            btn.userType === 'CF' ? `128px` : `64px`
          }] cursor-pointer mx-auto ${
            btn.userType === 'CF' ? `m-3` : `mt-3 mb-3 ml-2 mr-0`
          }
            ${
            hover
              ? `stroke:dashboardButtonTextHover`
              : `stroke-dashboardButtonText`
          }
            ${
            hover ? `fill:dashboardButtonTextHover` : `fill-dashboardButtonText`
          }
            `}
          width="128px"
          height="128px"
          onClick={() => {
            if (onClick) {
              onClick(btn);
            }
          }}
        />
        <div>
          <div className="text-center text-2xl font-bold mb-2">{btn.title}</div>
          <div className="text-center font-thin text-base max-w-xs">{btn.description}</div>
        </div>
      </div>
    </KdButton>
  );
};

export default LandingPageButton;
