// images (icons) used in landing page buttons
import { ReactComponent as businessImg } from '../assets/images/organisation.svg';
import { ReactComponent as vanImage } from '../assets/images/van.svg';
import { LandingButtonType } from '../types/landingButton';

const getLandingPageButtons = (
  t: Function,
  laUserFlow: boolean
): LandingButtonType[] => [
  {
    title: t('Commercial Operator'),
    description: t('Select this option if you are a business owner or transport manager.'),
    img: vanImage,
    linkHref: '/login',
    visible: true,
    testId: 'commercial-fleet-button',
    userType: 'CF',
  },
  {
    title: t('Local Authority'),
    description: t('Select this option if you are a Local Authority administrator.'),
    img: businessImg,
    linkHref: '/login-la',
    visible: laUserFlow,
    testId: 'local-authority-button',
    userType: 'LA',
  },
];

export default getLandingPageButtons;
