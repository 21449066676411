import { datadogRum } from '@datadog/browser-rum';
import { StyledEngineProvider } from '@mui/material';
import { configureStore } from '@reduxjs/toolkit';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import semver from 'semver';

import config from '../package.json';
import App from './App';
import GlobalStyle from './GlobalStyle';
import Head from './Head';
import {
  convertEnvName,
  matchToObjectTemplate,
  persistStoreToSessionStorage,
} from './functions/utils';
import './i18n';
import './scss/style.scss';
import { initialCreateBookingStoreState as createBookingStore } from './store/createBookingStore';
import { initialFeatureFlagState as featureFlagStore } from './store/featureFlagStore';
import rootReducer from './store/index';
import { initialManageBookingState as manageBookingsStore } from './store/manageBookingsStore';
import { initialManageUserState as manageUserStore } from './store/manageUserStore';
import { initialVehicleState as manageVehicleStore } from './store/manageVehiclesStore';
import { initialViewState as viewStore } from './store/viewStore';

const persistedState =
  JSON.parse(localStorage.getItem('reduxStore') ?? 'null') !== null
    ? JSON.parse(localStorage.getItem('reduxStore') ?? 'null')
    : {};

const reduxStoreTemplate = {
  createBookingStore,
  manageBookingsStore,
  manageUserStore,
  manageVehicleStore,
  viewStore,
};

const completePersistedState = matchToObjectTemplate(
  persistedState,
  reduxStoreTemplate
);

const completeFlaggedState = {
  ...completePersistedState,
  featureFlagStore,
};

const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),

  preloadedState: completeFlaggedState,
});

if (window.location.hostname !== 'localhost') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID!,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN!,
    site: 'datadoghq.eu',
    service: 'kerb-delivery-web-ui',
    env: convertEnvName(process.env.REACT_APP_ENVIRONMENT!),
    version: `${semver.parse(config?.version)?.patch}`,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackViewsManually: true,
    trackAnonymousUser: true,
    traceSampleRate: 100,
    telemetrySampleRate: 100,
    enablePrivacyForActionName: true,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['feature_flags'],
    // allowedTracingUrls: [
    //   (url) => url.startsWith(process.env.REACT_APP_REST_URL),
    // ],
    beforeSend: () => {
      if (!store.getState().featureFlagStore['errorReporting']) {
        return false;
      }

      return true;
    },
  });

  datadogRum.startSessionReplayRecording();
} else {
  console.info('DataDog is disabled in development mode');
}

export type AppDispatch = typeof store.dispatch;

const container = document.querySelector('.root');

const root = ReactDOM.createRoot(container!);

persistStoreToSessionStorage(store);

root.render(
  <>
    <GlobalStyle />
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <Head />
        <App />
      </Provider>
    </StyledEngineProvider>
  </>
);

declare global {
  interface Window {
    Cypress: unknown;

    store: typeof store;
  }
}

if (window.Cypress) {
  window.store = store;
}

export default store;
